import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

import teamImg from "../images/team.png"

const AboutUs = () => {
  return (
    <Layout>
      <Seo title="Hakkımızda" />
      <div className="container team">
        <h2 className="title">Ekibimiz hakkında</h2>
        <img className="team-poster" src={teamImg} alt="makromusic team" />
        <p className="team-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum diam orci pretium a pharetra, feugiat cursus. Dictumst risus, sem egestas odio cras adipiscing vulputate. Nisi, risus in suscipit non. Non commodo volutpat, pharetra, vel.</p>
      </div>
    </Layout>
  )
}

export default AboutUs
